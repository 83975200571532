import React from 'react';
import '../../App.css';
import { Footer } from '../Footer';

export default function Realestate() {
  return(
    <div className='real-estate'>
      <div className='realEstate'>
        <h1>Simplifying Investment Opportunities for All</h1>
        <h3>Providing Access to Well-timed and Problem-free Real Estate Investment</h3>
      </div>
      <div className='pop'>
        <h1>McYouniverse Properties</h1>
        <p>
          We provide diverse array of contemporary housing units across all our estate development in a bid to cater 
          for our customers’ needs ranging from people looking to live in a modern and sustainable environment in 
          strategic locations within Nigeria, to people looking to make high yielding property investments within the country.
        </p>
      </div>
      < Footer />
    
    </div>

    

  );
}
