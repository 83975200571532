import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

export function Button({ link, className, children }) {
  return (
    <Link to={link} className={`btn ${className}`}>
      {children}
    </Link>
  );
}
