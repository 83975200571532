import React, { useState } from 'react';
import Rectangle35 from '../logo/get_in_touch.jpg';
import '../group/group7.css';

export default function Group7() {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Local server submission
        fetch('http://localhost:3000/submit-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then((response) => response.text())
        .then((data) => {
            alert(data); // Show response from local server
            // Optionally clear form fields on successful submission
            setFormData({
                fname: '',
                lname: '',
                email: '',
                message: ''
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });

        // Google Sheets API submission
        const formEle = document.querySelector(".form");
        const formDatab = new FormData(formEle);
        fetch(
            "https://api.sheetmonkey.io/form/5c4SK54341gqkR1den6RgJ",
            {
                method: "POST",
                body: formDatab
            }
        )
        .then((res) => res.json())
        .then((data) => {
            console.log(data); // Log response from Google Sheets API
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className='group-7' id='joinus-section'>
            <div className='group-7-img'>
                <img src={Rectangle35} alt='group-7-img' />
            </div>

            <div className='right-side'>
                <div className='header'>
                    <hr className='line-4' />
                    <h2>Get in touch</h2>
                </div>
                <div className='group-7-img2'>
                    <img src={Rectangle35} alt='group-7-img' />
                </div>
                <div className='info'>
                    <p>At McYouniverse Group, YOU are our priority. We are committed 
                        to proffering solutions to you every step of the way. Talk to 
                        us here.
                    </p>
                </div>
                <form className="form" action="http://localhost:3000/submit-form" method="POST" onSubmit={handleSubmit}>
                    <div className='Name-section'>
                        <label>
                            First Name
                            <input type="text" id='fname' placeholder="First name" value={formData.fname} onChange={handleChange} />
                        </label>
                        <label>
                            Last Name
                            <input type="text" id='lname' placeholder="Last name" value={formData.lname} onChange={handleChange} />
                        </label>
                    </div>
                    <label>
                        Email
                        <input type="text" id='email' placeholder="Email" value={formData.email} onChange={handleChange} />
                    </label>
                    <br />
                    <div className='message'>
                        <label>
                            Message
                            <input type="text" id='message' placeholder="Message" value={formData.message} onChange={handleChange} />
                        </label>
                        <br />
                    </div>
                    <div className='checkbox'>
                        <input type="checkbox" id="checkbox" name="checkbox" value="checkbox" />
                    </div>
                    <div className='submit'>
                        <button className='submit' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
