import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '../Button';
import '../group/group2.css';
import visionImage from '../logo/vision.jpg';
import missionImage from '../logo/mission.jpg';
import coreValuesImage from '../logo/core values.jpg';
import Lottie from 'react-lottie';
import animationData from '../0.json';

export default function Group2() {
  const sliderRef = useRef(null);

  const slides = [
    {
      imageUrl: visionImage,
      title: 'OUR VISION',
      description: 'To be a Leading Solutions-Providing Business Conglomerate of Real Estate, Agriculture, Information Technology, Haulage and Logistics in the Universe.',
      backgroundColor: '#9A031E',
      link: '/our-vision'
    },
    {
      imageUrl: missionImage,
      title: 'OUR MISSION',
      description: 'To integrate efficacy across diverse sectors of the economy, leading individuals to a wide array of solutions within and beyond Africa.',
      backgroundColor: '#9A031E',
      link: '/our-mission'
    },
    {
      imageUrl: coreValuesImage,
      title: 'OUR CORE VALUES',
      description: `
        <div class="core-value"><span class="core-heading">VIGOR</span> We deliver with efficacy and policy.</div>
        <div class="core-value"><span class="core-heading">INTEGRITY</span> We act in utmost good faith, we deliver on our words.</div>
        <div class="core-value"><span class="core-heading">NETWORK</span> We believe business thrive based on smooth and ideal company-client flow.</div>
        <div class="core-value"><span class="core-heading">EXCELLENCE</span> We crave for and offer top-notch services.</div>`,
      backgroundColor: '#9A031E',
      link: '/our-core-value',
      isCoreValues: true
    },
  ];

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerPadding: "160px",
    speed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          centerPadding: "0px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        }
      },
    ]
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      
      <div className='group-1'>
      <div className='gif'>
        <Lottie options={defaultOptions}  />
      </div>
        <div className='heading-1'>
          <h1 className='content-1'>We are a Solutions-Providing Business Conglomerate of 
            Real Estate, Agriculture, Information Technology, Haulage and Logistics.
          </h1>
          <div className='group1-button'>
            <li >
              <a href='/#joinus-section' className='btn-primary'>
               Get Started
              </a>
            </li> 
          </div>
        </div>
        <div className='heading-2'>
          <hr className='line-1'></hr>
          <p className='content-2'>With a passion to integrate efficacy across diverse sectors of the economy,
           we are leading individuals to a wide array of solutions.
          </p>
        </div>
      </div>

      <div className="group-2">
        <div className="control">
          <div className="previous">
            <button onClick={handlePrev}>&#8592;</button>
          </div>
          <div className="next">
            <button onClick={handleNext}>Next &#8594;</button>
          </div>
        </div>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div key={index} className='group-2-slide'>
              <div className="slide-info" style={{ backgroundColor: slide.backgroundColor }}>
                <h1>{slide.title}</h1>
                <div className={`description ${slide.isCoreValues ? 'core-values-grid' : ''}`} dangerouslySetInnerHTML={{ __html: slide.description }} />
              </div>
              <img src={slide.imageUrl} alt='property' />
            </div>
          ))}
        </Slider>
      </div>

      <div className='bold-text'>
        <p>“If the Youniverse does not need us, we would not be here.”</p>
      </div>
    </>
  );
}
