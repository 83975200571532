import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Sign1 from '../logo/Layer_1.svg';
import Sign2 from '../logo/Layer_1 (1).svg';
import Sign3 from '../logo/Layer_1_1_.svg';
import Sign4 from '../logo/logistics.svg';
import '../group/group3.css';
import { Button } from '../Button';

export default function Group3() {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      imageUrl: Sign1,
      title: 'REAL ESTATE',
      description: 'With a passion to simplify investment opportunities, we are harnessing the latest Real Estate Development and Management expertise to provide access to well-timed and problem-free landed properties, homes and apartments acquisition, thereby securing a lifetime of wealth creation for everyone.',
      link: '/real-estate'
    },
    {
      imageUrl: Sign2,
      title: 'AGRICULTURE',
      description: 'McYounifarms is the Agricultural Arm of McYouniverse Group at the forefront of Crop Production, Animal Husbandry, Manufacturing and Processing - in fulfillment of the Sustainable Development Goal (SDG 2); Zero Hunger. We are building an integrated farming system to create a limitless food-supply chain that will nourish the world.',
      link: '/agriculture'
    },
    {
      imageUrl: Sign3,
      title: 'INFORMATION TECHNOLOGY',
      description: 'At McYounitech, we are on a mission to empower individuals for technological advancement. We designed a flagship platform, Codeverse Africa, aimed at creating a pool of tech enthusiasts through peer-to-peer learning, connecting learners with fun and engaging programs for global tech innovation.',
      link: 'https://codeverse.africa/'
    },
    {
      imageUrl: Sign4,
      title: 'HAULAGE AND LOGISTICS',
      description: 'We are constantly pushing the boundaries of mobility to help our customers achieve their delivery schedules and timelines. Our Haulage and Logistics Subsidiary is fast-growing, providing access to swift and reliable mobility of people and goods across Africa.',
      link: '/'
    },
  ];

  const settings = {
    dots:false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed:500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        }
      }
    ]
  };

  const handlePrev = () => {
    setActiveIndex(prevIndex => {
      const newIndex = prevIndex === 0 ? slides.length - 1 : prevIndex - 1;
      updateLineIndicator(newIndex);
      return newIndex;
    });
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    setActiveIndex(prevIndex => {
      const newIndex = prevIndex === slides.length - 1 ? 0 : prevIndex + 1;
      updateLineIndicator(newIndex);
      return newIndex;
    });
    sliderRef.current.slickNext();
  };

  const updateLineIndicator = (index) => {
    const indicator = document.querySelector('.line-3-indicator');
    const slideWidth = sliderRef.current.innerSlider.list.clientWidth / slides.length;
    indicator.style.transform = `translateX(${index - slideWidth}px)`;
  };

  return (
    <div className='group-3'>
      <hr className='line-2' />
      <h2>Discover the Array of Solutions we proffer at McYouniverse Group</h2>
      <div className='box-container'>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div key={index} className={`flex-item ${activeIndex === index ? 'active' : ''}`}>
              <img src={slide.imageUrl} alt={`sign-${index + 1}`} />
              <h3>{slide.title}</h3>
              <div className='Read'>
                <p>{slide.description}</p>
              </div>
              <div className='group-3-button'>
                <Button link={slide.link}  className="btn-secondary">Learn More</Button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className='group-3-line'>
        <hr className='line-3'></hr>
        <div className='line-3-indicator' style={{ transform: `translateX(${activeIndex * 100}%)` }}></div>
      </div>
      <div className='group-3-control'>
        <button className='previous' onClick={handlePrev}>&#8592;</button>
        <button className='next' onClick={handleNext}>Next &#8594;</button>
      </div>
    </div>
  );
}
