import React, { useState } from 'react';
import './Footer.css';
import Facebook from './logo/d.svg';
import Instagram from './logo/Instagram.svg';
import Twitter from './logo/twitter.svg';
import Linkedin from './logo/linkdin.svg';
import Logo from './logo/New logo 1 2.png';

export function Footer() {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <footer id='footer-section'>
      <div>
        <hr className='line-8'></hr>
      </div>

      <div className='footer'>
        <div className='first-part'>
          <div className='left-side-first'>
            <a href='/' className='navbar-logo'>
              <img src={Logo} alt="Logo" className="logo-image" />
            </a>
            <div className='line-6'></div>
            <div className='address'>
              <p>House 9A, A Close, 11 Road, Gowon Estate,
                Egbeda Alimosho, Lagos Nigeria.
              </p>
              <p className='abuja'>
                House 3, Plot C, Aquamarine Estate, Phase 2, Apo, Abuja.
              </p>
            </div>
          </div>

          <div className='right-side-first'>
            <button className='call'>Talk to Us</button>
          </div>
        </div>
        <div className='second-part'>
          <div className='left-side-second'>
            <ul>
              <li className='nav-item-footer'>
                <a href='/' className='nav-links-footer'>
                  Home
                </a>
              </li>
              <li className='nav-item-footer'>
                <a href='/about' className='nav-links-footer'>
                  About Us
                </a>
              </li> 
              <li className='nav-item-footer'>
                <a href='/#joinus-section' className='nav-links-footer'>
                  Join Our Team
                </a>
              </li> 
              <li className='nav-item-footer'>
                <a href='/about' className='nav-links-footer'>
                  Blog
                </a>
              </li>
              <li className='nav-item-footer'>
                <a href='/#footer-section' className='nav-links-footer'>
                  Contact Us 
                </a>
              </li> 
            </ul>
          </div>

          <div className='right-side-second'>
            <hr className='line-4'></hr>
            <p>Join the newsletter and read the new post first</p>
            <div className='details'>
              <input 
                type='text' 
                placeholder="Enter your Email Address"
                value={inputValue}
                onChange={handleChange} 
              />
              <button>Join Now</button>
            </div> 
          </div>
        </div>
        <hr className='line-7'></hr>
        <div className='buttom'>
          <p>Copyright@2024 All Right Reserved <a href='' className='Link-word'>McYouniverse Group</a></p>
          <div className='social-media'>
            <ul>
              <li className='sm-item'>
                <a href="https://www.facebook.com/mcyouniverse.mcyouniverse">
                  <img src={Facebook} alt='fb' />
                </a>
              </li>
              <li className='sm-item'>
                <a href="https://www.instagram.com/mcyouniversegroup/">
                  <img src={Instagram} alt='insta' />
                </a>
              </li>
              <li className='sm-item'>
                <a href="https://twitter.com/McYouniverseGrp">
                  <img src={Twitter} alt='twit' />
                </a>
              </li>
              <li className='sm-item'>
                <a href="https://www.linkedin.com/company/mcyouniverse-investment-limited/">
                  <img src={Linkedin} alt='link' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
