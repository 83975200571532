import React from 'react';
import '../../App.css';
import Group2 from '../group/group2';
import Group3 from '../group/group3';
import Group4 from '../group/group4';
import Group5 from '../group/group5';
import Group6 from '../group/group6';
import BoardDirector from '../group/BoardDirector';
import Group7 from '../group/group7';
import { Footer } from '../Footer';


export default function Home() {

  
  return (
    <>
      <div className='Content'>
        <Group2 />
        <Group3 />
        < Group4 />          
        < Group5 />
        < Group6 />
        < BoardDirector/>
        < Group7 />
        < Footer />
        
       
      </div>
    </>
  );
}
