import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Logo from './logo/New logo 1 2.png';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={Logo} alt="Logo" className="logo-image" />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/real-estate' className='nav-links' onClick={closeMobileMenu}>
              Real Estate
            </Link>
          </li>
          <li className='nav-item'>
            <Link to= 'https://codeverse.africa/' className='nav-links' onClick={closeMobileMenu}>
              Technology
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/agriculture' className='nav-links' onClick={closeMobileMenu}>
              Agriculture
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Haulage
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
              About
            </Link>
          </li>
        </ul>
        <div className='nav-button'>
          <li >
            <a href='/#joinus-section' className='btn-primary'>
              Get Started
            </a>
          </li> 
        </div>
       
      </nav>
    </>
  );
}

export default Navbar;
