import React from 'react';
import '../../App.css';
import { Footer } from '../Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MG1 from '../logo/1.jpeg';
import MG2 from '../logo/2.jpeg';
import MG3 from '../logo/3.jpeg';
import MG4 from '../logo/4.jpeg';
import MG5 from '../logo/5.jpeg';
import MG6 from '../logo/6.jpeg';
import MG7 from '../logo/7.jpeg';
import MG8 from '../logo/8.jpeg';
import MG9 from '../logo/A.jpeg';
import MG10 from '../logo/C.jpeg';
import MG11 from '../logo/D.jpeg';
import MG12 from '../logo/E.jpeg';
import MG13 from '../logo/F.jpeg';
import MG14 from '../logo/G.jpeg';

const items = [
  { imageUrl: MG1 },
  { imageUrl: MG2 },
  { imageUrl: MG3 },
  { imageUrl: MG4 },
  { imageUrl: MG5 },
  { imageUrl: MG6 },
  { imageUrl: MG7 },
  { imageUrl: MG8 },
  { imageUrl: MG9 },
  { imageUrl: MG10 },
  { imageUrl: MG11 },
  { imageUrl: MG12 },
  { imageUrl: MG13 },
  { imageUrl: MG14 },
];


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        arrows: false,
      }
    }
  ]
};

export default function Agriculture() {
  return (
    <div className='Agric'>
      <div id='heading-content'>
      <div className='heading-content' >
        <div className='tag-1'>
        <h1>On A Mission to Create A Limitless Food-Supply Chain</h1>
        <h2>At the forefront of Crop Production, Animal Husbandry, Manufacturing and Production</h2>
        </div>
        <div className='tag-2'>
          <h3>The Sustainable Development Goal 2; Zero Hunger, propels us towards the provision of a limitless food-supply chain that will nourish the world. 
            Through the supply of our agro-products to households and companies, we are channeling our expertise to tackle food challenges facing Africa, 
            thereby ensuring that any and every one gets unparalleled access to uncompromised farm produce. We make sustainable food-security the heart our business, positioning us at the forefront of Crop Production, Animal Husbandry, Manufacturing and Processing.
          </h3>
        </div>
        
      </div>
      </div>
      <div className="agriculture">
        <div className="agriImages">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index}>
                  <img src={item.imageUrl} alt={`Agriculture Slide`} />
              </div>
            ))}
          </Slider>
        </div>
        <div className='agric-content'>
          <div className="agriInfo-1">
            <h1>Crop Production</h1>
            <p>From pioneering the cultivation of crops to offering nothing but the safest products to end users, our commitment to food-security remains unwavering. Our offerings span across a wide range of food production and services across Africa. We're only truly fulfilled if our produce reaches the unreached across the world, through the supply of agro-products such as vegetables; pepper, cucumber, watermelon, corn, cassava, palm oil.</p>
          </div>
          <div className="agriInfo-2">
            <h1>Animal Husbandry</h1>
            <p>Through the years, we have contributed to the Nigerian Agro-Sector, by supplying some of the best poultry products in the market along with pork, rabbit, and snails. In the face of economic challenges, our priority has always been to make quality food part of everyday life. We raise our livestocks naturally and we take pride in our commitment to diligence and satisfaction without compromise.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
