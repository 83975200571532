import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import RealEstate from './components/pages/RealEstate';
import Technology from './components/pages/Technology';
import Agriculture from './components/pages/Agriculture';
import Haulage from './components/pages/Haulage'; 
import About from './components/pages/About';
import GetStarted from './components/pages/GetStarted';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/real-estate" element={<RealEstate />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/agriculture" element={<Agriculture />} />
        <Route path="/haulage" element={<Haulage />} />
        <Route path="/about" element={<About />} />
        <Route path="/get-started" element={<GetStarted />} />
      </Routes>
    </Router>
  );
}

export default App;
